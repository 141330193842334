import { plans } from 'core'

export const useSidebarState = createSharedComposable(() => {
  const { user, ...session } = useSession()

  /** Only applicable for mobile screen */
  const isActive = ref(false)
  const planName = computed(() => plans[user.plan].name || 'Unknown')

  // Methods
  const toggleMenu = (state?: boolean) => isActive.value = state ?? !isActive.value
  const logout = () => session.logout()

  return { user, session, isActive, planName, toggleMenu, logout }
})
