<script lang="ts" setup>
import { useSidebarState } from './shared'

const { toggleMenu } = useSidebarState()
</script>

<template>
  <span class="md:hidden">
    <IconButton variant="ghost" size="base" icon="i-solar-hamburger-menu-outline" @resolve-click="toggleMenu" />
  </span>
</template>
