<script lang="ts" setup>
import { useSession } from 'core'
import { LoginScreen } from 'ui'

const props = defineProps<{
  title?: string
}>()

const session = useSession()

const title = computed(() => {
  const route = useRoute()
  return props.title || route.meta.title
})

useHead({ title })
</script>

<template>
  <main v-if="session.isSynced" class="size-full flex flex-row">
    <Sidebar />

    <section
      id="layout-content"
      class="content-grid p-6 gap-6 sm:p-12 sm:gap-12 flex-grow grid relative overflow-hidden overflow-y-auto"
    >
      <header id="layout-header" class="gap-3 min-h-48px flex flex-wrap items-center view-transition-layout-header">
        <SidebarCTA />

        <h2 class="gap-2 flex-center text-primary-500 text-heading-5 md:text-heading-1">
          <RouterLink v-if="$route.path !== '/'" to="/">
            <IconButton icon="i-material-symbols-light-arrow-back text-2xl text-primary-300" />
          </RouterLink>

          {{ title }}
        </h2>
      </header>

      <Spinner v-if="!session.user.email" class="text-primary-500 m-auto" />
      <slot v-else />
    </section>
  </main>

  <NuxtLayout v-else name="basic" class="flex-col-center">
    <LoginScreen v-if="!http.isAuthorized" class="m-auto" />

    <!-- first sync loader -->
    <div v-else-if="!session.isSynced" class="gap-5 flex-col-center text-center text-neutral-400">
      <LinearProgress height="4px" infinite class="max-w-200px" />
      Syncing your data
    </div>
  </NuxtLayout>
</template>

<style scoped lang="sass">
.content-grid
  grid-template: max-content 1fr / 1fr
</style>
